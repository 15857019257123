import * as React from 'react';
import moment from 'moment';
import { FORMAT_MOMENT } from '~/helpers/constants';
import { useSelector } from 'react-redux';
import { selectCompanyOptions } from '~/redux/selectors/companyOptionsSelectors';

const PX_PER_SECOND = 96 / 60 / 60;

export interface ICurrentTimeLine {
  beginTime: string;
}
const CurrentTimeLine: React.FC<ICurrentTimeLine> = ({ beginTime }) => {
  const timeZone = useSelector(selectCompanyOptions).time_zone;

  const [time, setTime] = React.useState<string>(
    moment()
      .subtract(moment().utcOffset(), 'minutes')
      .add(timeZone, 'hours')
      .format(FORMAT_MOMENT.YYYYMMDD_HHMMSS),
  );

  React.useEffect(() => {
    setTime(
      moment()
        .subtract(moment().utcOffset(), 'minutes')
        .add(timeZone, 'hours')
        .format(FORMAT_MOMENT.YYYYMMDD_HHMMSS),
    );
  }, []);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setTime(
        moment()
          .subtract(moment().utcOffset(), 'minutes')
          .add(timeZone, 'hours')
          .format(FORMAT_MOMENT.YYYYMMDD_HHMMSS),
      );
    }, 10000);
    return () => clearInterval(timer);
  }, [timeZone]);

  const yPos = (moment(time).unix() - moment(beginTime).unix()) * PX_PER_SECOND;

  if (yPos >= 0) {
    return <div className="currentTimeLine" style={{ top: yPos }}></div>;
  }

  return null;
};

export default CurrentTimeLine;
